import __request__ from 'routing/request';
interface Connections {
    readonly current: number;
    readonly available: number;
    readonly total_created: number;
}
interface DatabaseStats {
    readonly data_file_version: DataFileVersion;
    readonly data_size: number;
    readonly num_extents: number;
    readonly ns_size_mb: number;
    readonly index_size: number;
    readonly objects: number;
    readonly extent_free_list: ExtentFreeList;
    readonly file_size: number;
    readonly indexes: number;
    readonly collections: number;
    readonly avg_obj_size: number;
    readonly storage_size: number;
    readonly db: string;
}
interface Os {
    readonly name: string;
    readonly type: string;
    readonly version: string;
}
interface IndicesStats {
    readonly field_data_size: number;
    readonly id_cache_size: number;
    readonly store_size: number;
    readonly index_count: number;
}
interface Memory {
    readonly virtual: number;
    readonly mapped: number;
    readonly bits: number;
    readonly mapped_with_journal: number;
    readonly resident: number;
    readonly supported: boolean;
}
interface StorageEngine {
    readonly name: string;
}
interface System {
    readonly cpu_arch: string;
    readonly numa_enabled: boolean;
    readonly hostname: string;
    readonly mem_size_mb: number;
    readonly num_cores: number;
    readonly cpu_addr_size: number;
    readonly current_time: string;
}
interface ClusterHealth {
    readonly active_shards: number;
    readonly active_primary_shards: number;
    readonly initializing_shards: number;
    readonly unassigned_shards: number;
    readonly pending_tasks_time_in_queue: number[];
    readonly pending_tasks: number;
    readonly number_of_data_nodes: number;
    readonly timed_out: boolean;
    readonly number_of_nodes: number;
    readonly relocating_shards: number;
}
interface NodesStats {
    readonly total: number;
    readonly data_only: number;
    readonly master_data: number;
    readonly client: number;
    readonly master_only: number;
}
interface MongoStats {
    readonly servers: string[];
    readonly database_stats: DatabaseStats;
    readonly server_status: ServerStatus;
    readonly build_info: BuildInfo;
    readonly host_info: HostInfo;
}
interface ClusterStats {
    readonly mongo: MongoStats;
    readonly extractor_count: number;
    readonly extractor_count_by_type: {
        readonly [_key: string]: number;
    };
    readonly stream_count: number;
    readonly input_count_by_type: {
        readonly [_key: string]: number;
    };
    readonly stream_rule_count_by_stream: {
        readonly [_key: string]: number;
    };
    readonly user_count: number;
    readonly elasticsearch: ElasticsearchStats;
    readonly output_count: number;
    readonly output_count_by_type: {
        readonly [_key: string]: number;
    };
    readonly dashboard_count: number;
    readonly input_count: number;
    readonly stream_rule_count: number;
    readonly global_input_count: number;
}
interface Extra {
    readonly scheduler: string;
    readonly kernel_version: string;
    readonly cpu_features: string;
    readonly num_pages: number;
    readonly cpu_frequency_mhz: string;
    readonly max_open_files: number;
    readonly version_string: string;
    readonly libc_version: string;
    readonly page_size: number;
}
interface ElasticsearchStats {
    readonly cluster_version: string;
    readonly cluster_name: string;
    readonly nodes_stats: NodesStats;
    readonly cluster_health: ClusterHealth;
    readonly indices_stats: IndicesStats;
    readonly status: 'Red' | 'Yellow' | 'Green';
}
interface ExtentFreeList {
    readonly num: number;
    readonly total_size: number;
}
interface Network {
    readonly bytes_out: number;
    readonly bytes_in: number;
    readonly num_requests: number;
}
interface ServerStatus {
    readonly uptime_millis: number;
    readonly process: string;
    readonly memory: Memory;
    readonly local_time: string;
    readonly uptime_estimate: number;
    readonly host: string;
    readonly pid: number;
    readonly storage_engine: StorageEngine;
    readonly version: string;
    readonly connections: Connections;
    readonly uptime: number;
    readonly network: Network;
}
interface BuildInfo {
    readonly max_bson_object_size: number;
    readonly git_version: string;
    readonly sys_info: string;
    readonly allocator: string;
    readonly debug: boolean;
    readonly loader_flags: string;
    readonly version_array: number[];
    readonly bits: number;
    readonly compiler_flags: string;
    readonly javascript_engine: string;
    readonly version: string;
}
interface DataFileVersion {
    readonly major: number;
    readonly minor: number;
}
interface HostInfo {
    readonly system: System;
    readonly os: Os;
    readonly extra: Extra;
}
/**
 * Cluster status information.
 */
export function systemStats(): Promise<ClusterStats> {
    return __request__('GET', '/system/cluster/stats', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Elasticsearch information.
 */
export function elasticsearchStats(): Promise<ElasticsearchStats> {
    return __request__('GET', '/system/cluster/stats/elasticsearch', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * MongoDB information.
 */
export function mongoStats(): Promise<MongoStats> {
    return __request__('GET', '/system/cluster/stats/mongo', null, {}, {
        'Accept': ['application/json']
    });
}
